import { HomePageData } from "../../data/data";
import { Link, NavLink } from "react-router-dom";
function Contact() {
  return (
    <>
      {/* <!-- Hero Start --> */}
      <div class="container-fluid bg-primary p-5 bg-hero mb-5">
        <div class="row py-5">
          <div class="col-12 text-center">
            <h1 class="display-2 text-uppercase text-white mb-md-4">Contact</h1>
             <NavLink
                        to='/'
                        className="btn btn-primary py-md-3 px-md-5 me-3"
                        exact // Ensure exact match for top-level links
                      >
                        <i class="bi bi-arrow-right text-primary me-2"></i>
                        Home
                      </NavLink>
             <NavLink
                        to='/contact'
                        className="btn btn-light py-md-3 px-md-5"
                        exact // Ensure exact match for top-level links
                      >
                        Contact
                      </NavLink>
          
          </div>
        </div>
      </div>
      {/* <!-- Hero End --> */}

      {/* <!-- Contact Start --> */}
      <div class="container-fluid p-5">
        <div class="mb-5 text-center">
          <h5 class="text-primary text-uppercase">Contact Us</h5>
          <h1 class="display-3 text-uppercase mb-0">Get In Touch</h1>
        </div>
        <div class="row g-5 mb-5">
          <div class="col-lg-4">
            <div class="d-flex flex-column align-items-center bg-dark rounded text-center py-5 px-3">
              <div
                class="bg-primary rounded-circle d-flex align-items-center justify-content-center mb-3"
                style={{ width: "60px", height: "60px" }}
              >
                <i class="fa fa-map-marker-alt fs-4 text-white"></i>
              </div>
              <h5 class="text-uppercase text-primary">Visit Us</h5>
              <p class="text-secondary mb-0">{HomePageData.ADDRESS}</p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="d-flex flex-column align-items-center bg-dark rounded text-center py-5 px-3">
              <div
                class="bg-primary rounded-circle d-flex align-items-center justify-content-center mb-3"
                style={{ width: "60px", height: "60px" }}
              >
                <i class="fa fa-envelope fs-4 text-white"></i>
              </div>
              <h5 class="text-uppercase text-primary">Email Us</h5>
              <p class="text-secondary mb-0">{HomePageData.EMAIL}</p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="d-flex flex-column align-items-center bg-dark rounded text-center py-5 px-3">
              <div
                class="bg-primary rounded-circle d-flex align-items-center justify-content-center mb-3"
                style={{ width: "60px", height: "60px" }}
              >
                <i class="fa fa-phone fs-4 text-white"></i>
              </div>
              <h5 class="text-uppercase text-primary">Call Us</h5>
              <p class="text-secondary mb-0">{HomePageData.PHONE_NUMBER}</p>
            </div>
          </div>
        </div>
        <div class="row g-0">
          <div class="col-lg-6">
            <div class="bg-dark p-5">
              <form>
                <div class="row g-3">
                  <div class="col-6">
                    <input
                      type="text"
                      class="form-control bg-light border-0 px-4"
                      placeholder="Your Name"
                      style={{ height: "55px" }}
                    />
                  </div>
                  <div class="col-6">
                    <input
                      type="email"
                      class="form-control bg-light border-0 px-4"
                      placeholder="Your Email"
                      style={{ height: "55px" }}
                    />
                  </div>
                  <div class="col-12">
                    <input
                      type="text"
                      class="form-control bg-light border-0 px-4"
                      placeholder="Subject"
                      style={{ height: "55px" }}
                    />
                  </div>
                  <div class="col-12">
                    <textarea
                      class="form-control bg-light border-0 px-4 py-3"
                      rows="4"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div class="col-12">
                    <button class="btn btn-primary w-100 py-3" type="submit">
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-6">
            {/* <div style="width: 100%"><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=MG%20Shooting%20Academy,Prem%20Nagar,%20Ballabhgarh,%20Faridabad,%20Haryana%20121004+(MShooting%20Academyy%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps tracker sport</a></iframe></div>
            <iframe
              class="w-100"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Uncha%20Gaon%20Chungi,%20Mohna%20Road,%20Ballabhgarh,%20Faridabad,%20Haryana,india+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              frameborder="0"
              style={{ height: "457px", border: "0" }}
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe> */}
            <iframe class="w-100" width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=MG%20Shooting%20Academy,Prem%20Nagar,%20Ballabhgarh,%20Faridabad,%20Haryana%20121004+(MShooting%20Academyy%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" style={{ height: "457px", border: "0" }}><a href="https://www.gps.ie/">gps tracker sport</a></iframe>
          </div>
        </div>
      </div>
      {/* <!-- Contact End --> */}
    </>
  );
}

export default Contact;
