import axios from "axios";
const apiAuth = axios.create({
 baseURL: "https://mgshooting.in/rest_api_php/mgshooting-api",
});

const requestHandler = async (request) => {
  request.headers["Content-Type"] = "application/json";
  return request;
};

apiAuth.interceptors.request.use(async (request) => requestHandler(request));

export default apiAuth;
