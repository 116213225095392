import { HomePageData } from "../../data/data";
import { Link, NavLink } from "react-router-dom";
function PayAndPlay() {
  return (
    <>
      {/* <!-- Hero Start --> */}
      <div class="container-fluid bg-primary p-5 bg-hero mb-5">
        <div class="row py-5">
          <div class="col-12 text-center">
            <h1 class="display-2 text-uppercase text-white mb-md-4">
              Pay and Play
            </h1>
            <NavLink
                        to='/'
                        className="btn btn-primary py-md-3 px-md-5 me-3"
                        exact // Ensure exact match for top-level links
                      >
                        <i class="bi bi-arrow-right text-primary me-2"></i>
                        Home
                      </NavLink>
             <NavLink
                        to='/pay-play'
                        className="btn btn-light py-md-3 px-md-5"
                        exact // Ensure exact match for top-level links
                      >
                        Pay and Play
                      </NavLink>
          </div>
        </div>
      </div>
      {/* <!-- Hero End --> */}

      {/* <!-- Class Timetable Start --> */}
      <div class="container-fluid p-5">
        <div class="mb-5 text-center">
          <h5 class="text-primary text-uppercase">Class Schedule</h5>
          <h1 class="display-3 text-uppercase mb-0">Working Hours</h1>
        </div>
        <div class="tab-class text-center">
          <ul class="nav nav-pills d-inline-flex justify-content-center bg-dark text-uppercase rounded-pill mb-5">
            <li class="nav-item">
              <a
                class="nav-link rounded-pill text-white active"
                data-bs-toggle="pill"
                href="#tab-1"
              >
                Monday
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link rounded-pill text-white"
                data-bs-toggle="pill"
                href="#tab-2"
              >
                Tuesday
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link rounded-pill text-white"
                data-bs-toggle="pill"
                href="#tab-3"
              >
                Wednesday
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link rounded-pill text-white"
                data-bs-toggle="pill"
                href="#tab-4"
              >
                Thursday
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link rounded-pill text-white"
                data-bs-toggle="pill"
                href="#tab-5"
              >
                Friday
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link rounded-pill text-white"
                data-bs-toggle="pill"
                href="#tab-6"
              >
                Saturday
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link rounded-pill text-white"
                data-bs-toggle="pill"
                href="#tab-7"
              >
                Sunday
              </a>
            </li>
          </ul>
          <div className="tab-content">
            {HomePageData.CLASS.map((item, index) => (
              <div
                id={item.tab}
                className={`tab-pane fade show p-0 ${
                  index === 0 ? "active" : ""
                }`}
                key={index}
              >
                <div className="row g-5">
                  {item.timings.map((timingItem, timingIndex) => (
                    <div
                      className="col-lg-3 col-md-4 col-sm-6"
                      key={timingIndex}
                    >
                      <div className="bg-dark rounded text-center py-5 px-3">
                        <h6 className="text-uppercase text-light mb-3">
                          {timingItem.timing}
                        </h6>
                        {/* Add additional elements or data here if needed */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <!-- Class Timetable Start --> */}
    </>
  );
}

export default PayAndPlay;
