import { HomePageData } from "../../data/data";
import { Link, NavLink } from "react-router-dom";
function EventPage() {
  return (
    <>
      {/* <!-- Hero Start --> */}
      <div class="container-fluid bg-primary p-5 bg-hero mb-5">
        <div class="row py-5">
          <div class="col-12 text-center">
            <h1 class="display-2 text-uppercase text-white mb-md-4">Events</h1>
            <NavLink
                        to='/'
                        className="btn btn-primary py-md-3 px-md-5 me-3"
                        exact // Ensure exact match for top-level links
                      >
                        <i class="bi bi-arrow-right text-primary me-2"></i>
                        Home
                      </NavLink>
             <NavLink
                        to='/event'
                        className="btn btn-light py-md-3 px-md-5"
                        exact // Ensure exact match for top-level links
                      >
                        Event
                      </NavLink>
          </div>
        </div>
      </div>
      {/* <!-- Hero End --> */}

      {/* <!-- Blog Start --> */}
      <div class="container-fluid p-5">
        <div class="row g-5">
          {/* <!-- Blog list Start --> */}
          <div class="col-lg-12">
            <div class="row g-5">
              {HomePageData.EVENTS.map((item, index) => (
              <div class="col-md-3">
                <div class="blog-item">
                  <div class="position-relative overflow-hidden rounded-top">
                    <img class="img-fluid" src={item.imgpath} alt="" />
                  </div>
                  <div class="bg-dark d-flex align-items-center rounded-bottom p-4">
                    <div class="flex-shrink-0 text-center text-secondary border-end border-secondary pe-3 me-3">
                      <span>{item.date}</span>
                      <h6 class="text-light text-uppercase mb-0">{item.month}</h6>
                      <span>{item.year}</span>
                    </div>
                    <a class="h5 text-uppercase text-light" href="">
                      {item.title}
                    </a>
                  </div>
                </div>
              </div>
                ))}
            </div>
          </div>
          {/* <!-- Blog list End --> */}
        </div>
      </div>
      {/* <!-- Blog End --> */}
    </>
  );
}

export default EventPage;
