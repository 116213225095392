const HomePageData = {
  HEADING: "",
  EMAIL: "info@mgshooting.in",
  PHONE_NUMBER: "+91 98995 66733",
  ORG_NAME: " MG Shooting Academy",
  JOIN_US: "Join Us",
  ADDRESS: "Uncha Gaon Chungi, Mohna Road, Ballabhgarh, Faridabad, Haryana",
  GET_IN_TOUCH: "Get In Touch",
  NAV_ITEM: [
    {
      name: "Home",
      route: "/",
      isDropdown: false,
      isClicked: false,
    },
    {
      name: "About",
      route: "/about",
      isDropdown: false,
      isClicked: false,
    },
    {
      name: "Gallery",
      route: "/gallery",
      isDropdown: false,
      isClicked: false,
    },
    {
      name: "Events",
      isDropdown: false,
      route: "/event",
      isClicked: false,
      // dropdownItem: [
      //   {
      //     name: "Event 1",
      //     route: "/event",
      //   },
      // ],
    },
    {
      name: "Pay and Play",
      route: "/pay-play",
      isDropdown: false,
      isClicked: false,
    },
    {
      name: "Contact",
      route: "/contact",
      isDropdown: false,
      isClicked: false,
    },
  ],
  FOOTER_NAV: [
    {
      heading: "Quick Links",
      links: [
        {
          name: "Home",
          route: "/",
          isDropdown: false,
          isClicked: false,
        },
        {
          name: "About",
          route: "/about",
          isDropdown: false,
          isClicked: false,
        },
        {
          name: "Gallery",
          route: "/gallery",
          isDropdown: false,
          isClicked: false,
        },
      ],
    },
    {
      heading: "Popular Links",
      links: [
        {
          name: "Events",
          isDropdown: false,
          route: "/event",
          isClicked: false,
          // dropdownItem: [
          //   {
          //     name: "Event 1",
          //     route: "/event",
          //   },
          // ],
        },
        {
          name: "Pay and Play",
          route: "/pay-play",
          isDropdown: false,
          isClicked: false,
        },
        {
          name: "Contact",
          route: "/contact",
          isDropdown: false,
          isClicked: false,
        },
      ],
    },
  ],
  SLIDER_HOME: [
    {
      imgpath: "img/carousel-3.jpg",
      title: " BEST SHOOTING CENTER",
      sub_title: "Shooting & Training",
      active: "active",
    },
    {
      imgpath: "img/carousel-1.jpg",
      title: " BEST SHOOTING CENTER",
      sub_title: "Shooting & Training",
      active: "",
    },
    {
      imgpath: "img/carousel-2.jpg",
      title: " BEST SHOOTING CENTER",
      sub_title: "Shooting & Training",
      active: "",
    },
  ],
  //HOME About Start
  ABOUT_HEADING: "About Us",
  ABOUT_SUB_HEADING: "Welcome to MG Shooting Academy",
  WHY_CHOOSE_US: "Why Choose Us",
  ABOUT_DETAIL_1: 'Welcome to Modern Gun’s Shooting Academy where we are dedicated to providing exceptional firearms training and fostering a culture of safety, responsibility, and Academy, we believe in empowering individuals with the knowledge and skills necessary to navigate the world of firearms confidently.',
  ABOUT_DETAIL_2: "At Modern Gun’s Shooting Academy, we are dedicated to fostering a community where individuals can develop their skills, build confidence, and engage in responsible firearm practices. Whether you are a first-time shooter or a seasoned professional, we are here to support your journey in a welcoming and inclusive atmosphere.",
  // About END
  //Commitments
  COMMITMENTS_HEADING: 'Commitment',
  COMMITMENTS_SUB_HEADING: 'Our Commitment to You',
  COMMITMENTS_TOP_CONTENT:'At Modern Gun’s Shooting Academy, we are dedicated to fostering a community where individuals can develop their skills, build confidence, and engage in responsible firearm practices. Whether you are a first-time shooter or a seasoned professional, we are here to support your journey in a welcoming and inclusive atmosphere.',
  COMMITMENTS_HOME: [
    {
      title: "Safety First",
      description: "Welcome to Modern Gun’s Shooting Academy where we are dedicated to providing exceptional firearms training and fostering a culture of safety, responsibility, and proficiency in the use of firearms. At Modern Gun’s Shooting Academy, we believe in empowering individuals with the knowledge and skills necessary to navigate the world of firearms confidently.",
    },
     {
      title: "Education and Proficiency",
      description: "We are committed to providing top-tier education for individuals at all skill levels. From foundational courses in firearm safety to advanced tactical training, our programs are designed to enhance marksmanship and promote lifelong learning.",
    },
      {
      title: "Ethical and Responsible Firearm Use",
      description: "We emphasize the importance of ethical decision-making and responsible firearm ownership. Our participants not only develop technical proficiency but also gain a deep understanding of the legal and ethical considerations surrounding firearm use",
    },
       {
      title: "Inclusive Community",
      description: "We believe in building an inclusive and supportive community of individuals passionate about firearms education. Regardless of background or experience, everyone is welcome at Modern Gun’s Shooting Academy.",
    },
        {
      title: "Continuous Improvement",
      description: "Committed to staying at the forefront of industry standards, we continually assess and update our curriculum, facilities, and training methodologies. Our goal is to provide the best possible learning experience for our participants. By upholding these principles, Modern Gun’s Shooting Academy seeks to be a beacon of excellence in firearms education, shaping responsible and skilled firearm enthusiasts who contribute positively to their communities.",
    },
  ],
  CLASS: [
    {
      tab: "tab-1",
      timings: [
        {
          timing: "6.00AM - 8.00AM",
          className: "",
        },
        {
          timing: "8.00AM - 10.00AM",
          className: "",
        },
        {
          timing: "6.00AM - 8.00AM",
          className: "",
        },
        {
          timing: "10.00AM - 12.00PM",
          className: "",
        },
        {
          timing: "2.00PM - 4.00PM",
          className: "",
        },
        {
          timing: "4.00PM - 6.00PM",
          className: "",
        },
        {
          timing: "6.00PM - 8.00PM",
          className: "",
        },
        {
          timing: "8.00PM - 10.00PM",
          className: "",
        },
      ],
    },
    {
      tab: "tab-2",
      timings: [
        {
          timing: "6.00AM - 8.00AM",
          className: "",
        },
        {
          timing: "8.00AM - 10.00AM",
          className: "",
        },
        {
          timing: "6.00AM - 8.00AM",
          className: "",
        },
        {
          timing: "10.00AM - 12.00PM",
          className: "",
        },
        {
          timing: "2.00PM - 4.00PM",
          className: "",
        },
        {
          timing: "4.00PM - 6.00PM",
          className: "",
        },
        {
          timing: "6.00PM - 8.00PM",
          className: "",
        },
        {
          timing: "8.00PM - 10.00PM",
          className: "",
        },
      ],
    },
    {
      tab: "tab-3",
      timings: [
        {
          timing: "6.00AM - 8.00AM",
          className: "",
        },
        {
          timing: "8.00AM - 10.00AM",
          className: "",
        },
        {
          timing: "6.00AM - 8.00AM",
          className: "",
        },
        {
          timing: "10.00AM - 12.00PM",
          className: "",
        },
        {
          timing: "2.00PM - 4.00PM",
          className: "",
        },
        {
          timing: "4.00PM - 6.00PM",
          className: "",
        },
        {
          timing: "6.00PM - 8.00PM",
          className: "",
        },
        {
          timing: "8.00PM - 10.00PM",
          className: "",
        },
      ],
    },
    {
      tab: "tab-4",
      timings: [
        {
          timing: "6.00AM - 8.00AM",
          className: "",
        },
        {
          timing: "8.00AM - 10.00AM",
          className: "",
        },
        {
          timing: "6.00AM - 8.00AM",
          className: "",
        },
        {
          timing: "10.00AM - 12.00PM",
          className: "",
        },
        {
          timing: "2.00PM - 4.00PM",
          className: "",
        },
        {
          timing: "4.00PM - 6.00PM",
          className: "",
        },
        {
          timing: "6.00PM - 8.00PM",
          className: "",
        },
        {
          timing: "8.00PM - 10.00PM",
          className: "",
        },
      ],
    },
    {
      tab: "tab-5",
      timings: [
        {
          timing: "6.00AM - 8.00AM",
          className: "",
        },
        {
          timing: "8.00AM - 10.00AM",
          className: "",
        },
        {
          timing: "6.00AM - 8.00AM",
          className: "",
        },
        {
          timing: "10.00AM - 12.00PM",
          className: "",
        },
        {
          timing: "2.00PM - 4.00PM",
          className: "",
        },
        {
          timing: "4.00PM - 6.00PM",
          className: "",
        },
        {
          timing: "6.00PM - 8.00PM",
          className: "",
        },
        {
          timing: "8.00PM - 10.00PM",
          className: "",
        },
      ],
    },
    {
      tab: "tab-6",
      timings: [
        {
          timing: "6.00AM - 8.00AM",
          className: "",
        },
        {
          timing: "8.00AM - 10.00AM",
          className: "",
        },
        {
          timing: "6.00AM - 8.00AM",
          className: "",
        },
        {
          timing: "10.00AM - 12.00PM",
          className: "",
        },
        {
          timing: "2.00PM - 4.00PM",
          className: "",
        },
        {
          timing: "4.00PM - 6.00PM",
          className: "",
        },
        {
          timing: "6.00PM - 8.00PM",
          className: "",
        },
        {
          timing: "8.00PM - 10.00PM",
          className: "",
        },
      ],
    },
    {
      tab: "tab-7",
      timings: [
        {
          timing: "6.00AM - 8.00AM",
          className: "",
        },
        {
          timing: "8.00AM - 10.00AM",
          className: "",
        },
        {
          timing: "6.00AM - 8.00AM",
          className: "",
        },
        {
          timing: "10.00AM - 12.00PM",
          className: "",
        },
        {
          timing: "2.00PM - 4.00PM",
          className: "",
        },
        {
          timing: "4.00PM - 6.00PM",
          className: "",
        },
        {
          timing: "6.00PM - 8.00PM",
          className: "",
        },
        {
          timing: "8.00PM - 10.00PM",
          className: "",
        },
      ],
    },
  ],
  GALLERY: [
    {
      imgpath: "img/gallery_1.jpeg",
      title: "Inauguration",
      sub_title: ""
    },
     {
      imgpath: "img/gallery_2.jpeg",
      title: "Inauguration",
      sub_title: ""
    },
      {
      imgpath: "img/gallery_3.jpeg",
      title: "Inauguration",
      sub_title: ""
    },
       {
      imgpath: "img/gallery_4.jpeg",
      title: "Inauguration",
      sub_title: ""
    },
  ],
  EVENTS: [
  {
      imgpath: "img/event1.jpeg",
      title: "SUMMER CAMP",
      sub_title: "",
      date: "FROM 19th",
      month: 'May',
      Year:'2024'
    },
    {
      imgpath: "img/event2.jpeg",
      title: "INAUGURATION CEREMONY",
      sub_title: "",
      date: "28",
      month: 'April',
      Year:'2024'
    },
    {
      imgpath: "img/event3.jpeg",
      title: "INAUGURATION CEREMONY",
      sub_title: "",
      date: "28",
      month: 'April',
      Year:'2024'
    },
    
  ],
  FACEBOOK: 'https://www.facebook.com/profile.php?id=61556031130862&mibextid=ZbWKwL',
  INSTAGRAM:"https://www.instagram.com/mgshooting_academy?utm_source=qr&igsh=MWh1NGU5OTBkcjdlcg==",
  YOUTUBE:"https://youtube.com/@MGShootingAcademy-ti1jz?si=ShtMhXB-ObZjryvT"
};
export { HomePageData };
