import "../../App.css";
import { HomePageData } from "../../data/data";
import { Link, NavLink } from "react-router-dom";

// function ShowIfisDropdown({ isDropdown, children }) {
//   if (isDropdown) {
//     return children;
//   } else {
//     return null;
//   }
// }
function Headers() {
  return (
    <>
      {" "}
      <div class="container-fluid bg-dark px-0">
        <div class="row gx-0">
          <div class="col-lg-3 bg-dark d-none d-lg-block">
            <a
              href="index.html"
              class="navbar-brand w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center"
            >
              <img
                src="img/logo.png"
                style={{
                  width: "150px",
                  filter: "invert(1)",
                  mixBlendMode: "lighten",
                }}
              />
              <h5 class="m-0 display-6 text-primary text-uppercase">
                Modern Gun's
                <br />
                Shooting Academy
              </h5>
              {/* <img
                src="img/logo2.jpeg"
                style={{
                  width: "100%",
                }}
              /> */}
            </a>
          </div>
          <div class="col-lg-9">
            <div class="row gx-0 bg-secondary d-none d-lg-flex">
              <div class="col-lg-7 px-5 text-start">
                <div class="h-100 d-inline-flex align-items-center py-2 me-4">
                  <i class="fa fa-envelope text-primary me-2"></i>
                  <h6 class="mb-0">{HomePageData.EMAIL}</h6>
                </div>
                <div class="h-100 d-inline-flex align-items-center py-2">
                  <i class="fa fa-phone-alt text-primary me-2"></i>
                  <h6 class="mb-0">{HomePageData.PHONE_NUMBER}</h6>
                </div>
              </div>
              <div class="col-lg-5 px-5 text-end">
                <div class="d-inline-flex align-items-center py-2">
                  <a
                    class="btn btn-light btn-square rounded-circle me-2"
                    href={HomePageData.FACEBOOK} target="_blank"
                  >
                    <i class="fab fa-facebook-f"></i>
                  </a>
                  <a
                    class="btn btn-light btn-square rounded-circle me-2"
                     href={HomePageData.INSTAGRAM} target="_blank"
                  >
                    <i class="fab fa-instagram"></i>
                  </a>
                  <a class="btn btn-light btn-square rounded-circle" href={HomePageData.YOUTUBE} target="_blank">
                    <i class="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
            <nav className="navbar navbar-expand-lg bg-dark navbar-dark p-3 p-lg-0 px-lg-5">
              <NavLink
                to="/"
                className="navbar-brand d-block d-lg-none"
                activeClassName="active"
              >
                 <img
                src="img/logo2.jpeg"
                style={{
                  width: "100%",
                  mixBlendMode: "lighten",
                }}
              />
                {/* <h1 className="m-0 display-4 text-primary text-uppercase">
                  {HomePageData.ORG_NAME}
                </h1> */}
              </NavLink>
              <button
                type="button"
                className="navbar-toggler"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse justify-content-between"
                id="navbarCollapse"
              >
                <div className="navbar-nav mr-auto py-0">
                  {HomePageData.NAV_ITEM.map((item, index) => (
                    <div key={index}>
                      {!item.isDropdown ? (
                        <NavLink
                          to={item.route}
                          className="nav-item nav-link"
                          activeClassName="active"
                          exact // Ensure exact match for top-level links
                        >
                          <span>{item.name}</span>
                        </NavLink>
                      ) : (
                        <div className="nav-item dropdown">
                          <NavLink
                            to={item.route}
                            className="nav-link dropdown-toggle"
                            activeClassName="active" // Apply active class to dropdown parent
                            data-bs-toggle="dropdown"
                          >
                            {item.name}
                          </NavLink>
                          <div className="dropdown-menu rounded-0 m-0">
                            {item?.dropdownItem?.map(
                              (childitem, childIndex) => (
                                <NavLink
                                  to={childitem.route}
                                  className="dropdown-item"
                                  activeClassName="active"
                                  key={childIndex}
                                >
                                  {childitem.name}
                                </NavLink>
                              )
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <NavLink
                  to=""
                  className="btn btn-primary py-md-3 px-md-5 d-none d-lg-block"
                >
                  {HomePageData.JOIN_US}
                </NavLink>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default Headers;
