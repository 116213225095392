import { HomePageData } from "../../data/data";
import { Link, NavLink } from "react-router-dom";

function About() {
  return (
    <>
      {/* <!-- Hero Start --> */}
      <div class="container-fluid bg-primary p-5 bg-hero mb-5">
        <div class="row py-5">
          <div class="col-12 text-center">
            <h1 class="display-2 text-uppercase text-white mb-md-4">
             {HomePageData.ABOUT_HEADING }
            </h1>
             <NavLink
                        to='/'
                        className="btn btn-primary py-md-3 px-md-5 me-3"
                        exact // Ensure exact match for top-level links
                      >
                        <i class="bi bi-arrow-right text-primary me-2"></i>
                        Home
                      </NavLink>
             <NavLink
                        to='/about'
                        className="btn btn-light py-md-3 px-md-5"
                        exact // Ensure exact match for top-level links
                      >
                        About
                      </NavLink>
            
          </div>
        </div>
      </div>
      {/* <!-- Hero End --> */}

      {/* <!-- About Start --> */}
      <div class="container-fluid p-5">
        <div class="text-center">
          <h5 class="text-primary text-uppercase">{HomePageData.ABOUT_HEADING }</h5>
          <h1 class="display-3 text-uppercase mb-0">
            {HomePageData.ABOUT_SUB_HEADING}
          </h1>
        </div>
        <div class="tab-class text-center">
          {/* <!-- <h4 class="text-body mb-4">At the core of our mission is a commitment to:</h4> -->
      <!-- <p class="mb-4">Nonumy erat diam duo labore clita. Sit magna ipsum dolor sed ea duo at ut. Tempor sit lorem sit magna ipsum duo. Sit eos dolor ut sea rebum, diam sea rebum lorem kasd ut ipsum dolor est ipsum. Et stet amet justo amet clita erat, ipsum sed at ipsum eirmod labore lorem.</p> --> */}
          <div class="tab-content">
            <div class="row g-5 gb-5">
              <div class="col-lg-12 col-md-12">
                <div class="bg-light rounded text-center p-3">
                  {/* <!-- <i class="flaticon-six-pack display-1 text-primary"></i> -->
              <!-- <h3 class="text-uppercase my-2 ">Safety First</h3> --> */}
                  <p>
                   {HomePageData.ABOUT_DETAIL_1}
                  </p>
                  {/* <!-- <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a> --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}

      {/* <!--Commitments--> */}
      <div class="container-fluid p-5">
         <div class="text-center">
          <h5 class="text-primary text-uppercase">{HomePageData.COMMITMENTS_HEADING}</h5>
          <h1 class="display-3 text-uppercase mb-0">{ HomePageData.ABOUT_SUB_HEADING}</h1>
        </div>
        <div class="tab-class text-center">
          {/* <!-- <h4 class="text-body mb-4">At the core of our mission is a commitment to:</h4> --> */}
          <p class="mb-4">
           {HomePageData.COMMITMENTS_TOP_CONTENT}
          </p>
          <div class="tab-content">
            <div class="row g-5 gb-5">
              <div class="col-lg-12 col-md-12">
                {HomePageData.COMMITMENTS_HOME.map((item,index)=>(
                <div class="bg-light rounded text-start p-3">
                  {/* <!-- <i class="flaticon-six-pack display-1 text-primary"></i> --> */}
                  <h3 class="text-uppercase my-2">{item.title}</h3>
                  <p>
                   {item.description}
                  </p>
                  {/* <!-- <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a> --> */}
                </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--Commitments End--> */}

      {/* <!-- Programe Start --> */}
      <div class="container-fluid p-5">
        <div class="text-center">
          <h5 class="text-primary text-uppercase">Mission</h5>
          <h1 class="display-3 text-uppercase mb-0">Our Mission</h1>
        </div>
        <div class="tab-class text-center">
          <h4 class="text-body mb-4">
            At the core of our mission is a commitment to:
          </h4>
          {/* <!-- <p class="mb-4">Nonumy erat diam duo labore clita. Sit magna ipsum dolor sed ea duo at ut. Tempor sit lorem sit magna ipsum duo. Sit eos dolor ut sea rebum, diam sea rebum lorem kasd ut ipsum dolor est ipsum. Et stet amet justo amet clita erat, ipsum sed at ipsum eirmod labore lorem.</p> --> */}
          <div class="tab-content"></div>
        </div>
      </div>
      <div
        class="container-fluid programe position-relative px-5"
        style={{ marginBottom: "135px" }}
      >
        <div class="row g-5 gb-5">
          <div class="col-lg-4 col-md-6">
            <div class="bg-light rounded text-center p-3">
              {/* <!-- <i class="flaticon-six-pack display-1 text-primary"></i> --> */}
              <h3 class="text-uppercase my-2">Safety First</h3>
              <p>
                We prioritize the safety of our participants through rigorous
                adherence to safety protocols and best practices in firearm
                handling.
              </p>
              {/* <!-- <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a> --> */}
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="bg-light rounded text-center p-3">
              {/* <!-- <i class="flaticon-barbell display-1 text-primary"></i> --> */}
              <h3 class="text-uppercase my-2">Education</h3>
              <p>
                We aim to educate and equip individuals with the knowledge and
                skills needed for responsible firearm ownership and use.
              </p>
              {/* <!-- <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a> --> */}
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="bg-light rounded text-center p-3">
              {/* <!-- <i class="flaticon-bodybuilding display-1 text-primary"></i> --> */}
              <h3 class="text-uppercase my-2">Marksmanship Excellence</h3>
              <p>
                We strive to enhance marksmanship skills for participants of all
                levels, from beginners to seasoned professionals.
              </p>
              {/* <!-- <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a> --> */}
            </div>
          </div>
          {/* <!-- <div class="col-lg-12 col-md-6 text-center">
                <h1 class="text-uppercase text-light mb-4">30% Discount For This Summer</h1>
                <a href="" class="btn btn-primary py-3 px-5">Become A Member</a>
            </div> --> */}
        </div>
      </div>
      {/* <!-- Programe Start --> */}
      {/* <!--What Sets Us Apart--> */}
      <div class="container-fluid p-5">
        <div class="text-center">
          {/* <!-- <h5 class="text-primary text-uppercase">Commitment</h5> --> */}
          <h1 class="display-3 text-uppercase mb-0">What Sets Us Apart</h1>
        </div>
        <div class="tab-class text-center">
          <h4 class="text-body mb-4"></h4>
          {/* <!-- <p class="mb-4">At Modern Gun’s Shooting Academy, we are dedicated to fostering a community where
            individuals can develop their skills, build confidence, and engage in responsible firearm practices.
            Whether you are a first-time shooter or a seasoned professional, we are here to support your journey in
            a welcoming and inclusive atmosphere.</p> --> */}
          <div class="tab-content">
            <div class="row g-5 gb-5">
              <div class="col-lg-12 col-md-12">
                <div class="bg-light rounded text-start p-3">
                  {/* <!-- <i class="flaticon-six-pack display-1 text-primary"></i> --> */}
                  <h3 class="text-uppercase my-2">Experienced Instructors</h3>
                  <p>
                    Our team of experienced and certified instructors brings a
                    wealth of knowledge from diverse backgrounds, including law
                    enforcement, military, and competitive shooting.
                  </p>
                  {/* <!-- <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a> --> */}
                </div>
                <div class="bg-light rounded text-start p-3">
                  {/* <!-- <i class="flaticon-six-pack display-1 text-primary"></i> --> */}
                  <h3 class="text-uppercase my-2">
                    State-of-the-Art Facilities
                  </h3>
                  <p>
                    Modern Gun’s Shooting Academy boasts modern and
                    well-equipped shooting ranges to provide a safe and dynamic
                    learning environment.
                  </p>
                  {/* <!-- <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a> --> */}
                </div>
                <div class="bg-light rounded text-start p-3">
                  {/* <!-- <i class="flaticon-six-pack display-1 text-primary"></i> --> */}
                  <h3 class="text-uppercase my-2">Comprehensive Courses</h3>
                  <p>
                    From basic firearm safety courses to specialized training in
                    personal protection and tactical applications, we offer a
                    range of courses to meet the diverse needs of our
                    participants. <br />
                    <br />
                    At Modern Gun’s Shooting Academy, our mission is to empower
                    individuals through comprehensive firearms education,
                    promoting safety, proficiency, and responsibility in firearm
                    ownership and usage. We are dedicated to creating a learning
                    environment that fosters skill development, builds
                    confidence, and instills a deep understanding of the ethical
                    and legal aspects of firearms.
                  </p>
                  {/* <!-- <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a> --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--What Sets Us Apart End--> */}

      {/* <!--Our Aspirations:--> */}
      <div class="container-fluid p-5">
        <div class="text-center">
          <h5 class="text-primary text-uppercase">Aspirations</h5>
          <h1 class="display-3 text-uppercase mb-0">Our Aspirations</h1>
        </div>
        <div class="tab-class text-center">
          <h4 class="text-body mb-4"></h4>
          {/* <!-- <p class="mb-4">At Modern Gun’s Shooting Academy, we are dedicated to fostering a community where
            individuals can develop their skills, build confidence, and engage in responsible firearm practices.
            Whether you are a first-time shooter or a seasoned professional, we are here to support your journey in
            a welcoming and inclusive atmosphere.</p> --> */}
          <div class="tab-content">
            <div class="row g-5 gb-5">
              <div class="col-lg-12 col-md-12">
                <div class="bg-light rounded text-start p-3">
                  {/* <!-- <i class="flaticon-six-pack display-1 text-primary"></i> --> */}
                  <h3 class="text-uppercase my-2">Leadership in Education</h3>
                  <p>
                    We strive to be recognized as a global leader in firearms
                    education, continually innovating and setting new standards
                    for comprehensive, accessible, and effective training
                    programs.
                  </p>
                  {/* <!-- <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a> --> */}
                </div>
                <div class="bg-light rounded text-start p-3">
                  {/* <!-- <i class="flaticon-six-pack display-1 text-primary"></i> --> */}
                  <h3 class="text-uppercase my-2">Community Impact</h3>
                  <p>
                    We aim to positively influence the wider community by
                    producing responsible firearm owners who contribute to
                    public safety, promote ethical values, and engage in civic
                    responsibility.
                  </p>
                  {/* <!-- <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a> --> */}
                </div>
                <div class="bg-light rounded text-start p-3">
                  {/* <!-- <i class="flaticon-six-pack display-1 text-primary"></i> --> */}
                  <h3 class="text-uppercase my-2">Diversity and Inclusion</h3>
                  <p>
                    Our vision includes fostering a diverse and inclusive
                    community within our academy, where individuals from all
                    backgrounds feel welcome, respected, and supported in their
                    pursuit of firearms education.
                  </p>
                  {/* <!-- <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a> --> */}
                </div>
                <div class="bg-light rounded text-start p-3">
                  {/* <!-- <i class="flaticon-six-pack display-1 text-primary"></i> --> */}
                  <h3 class="text-uppercase my-2">Technological Advancement</h3>
                  <p>
                    We aspire to leverage cutting-edge technology to enhance our
                    training methodologies, providing participants with
                    state-of-the-art facilities and resources for an
                    unparalleled learning experience.
                  </p>
                  {/* <!-- <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a> --> */}
                </div>
                <div class="bg-light rounded text-start p-3">
                  {/* <!-- <i class="flaticon-six-pack display-1 text-primary"></i> --> */}
                  <h3 class="text-uppercase my-2">Continuous Innovation</h3>
                  <p>
                    We commit to a culture of continuous improvement and
                    innovation, adapting to emerging trends in firearms
                    education, safety, and technology to ensure our participants
                    receive the highest quality training available.
                  </p>
                  {/* <!-- <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a> --> */}
                </div>
                <div class="bg-light rounded text-start p-3">
                  {/* <!-- <i class="flaticon-six-pack display-1 text-primary"></i> --> */}
                  <h3 class="text-uppercase my-2">Global Influence</h3>
                  <p>
                    With a commitment to excellence and responsibility, we
                    envision our academy's positive influence extending beyond
                    borders, contributing to global conversations on firearm
                    safety, education, and responsible use. <br />
                    <br />
                    By relentlessly pursuing these aspirations, Modern Gun’s
                    Shooting Academy aims to be a catalyst for positive change
                    in the firearm education landscape, shaping a future where
                    individuals are equipped with the skills and mindset to
                    responsibly and ethically engage with firearms.
                  </p>
                  {/* <!-- <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a> --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--Our Aspirations: End--> */}
    </>
  );
}

export default About;
