import "../../App.css";
import { HomePageData } from "../../data/data";
import { Link, NavLink } from "react-router-dom";
function Footers() {
  return (
    <>
      <div class="container-fluid bg-dark text-secondary px-5 mt-5">
        <div class="row gx-5">
          <div class="col-lg-12 col-md-12">
            <div class="row gx-5">
              <div class="col-lg-4 col-md-12 pt-5 mb-5">
                <h4 class="text-uppercase text-light mb-4">
                  {HomePageData.GET_IN_TOUCH}
                </h4>
                <div class="d-flex mb-2">
                  <i class="bi bi-geo-alt text-primary me-2"></i>
                  <p class="mb-0">{HomePageData.ADDRESS}</p>
                </div>
                <div class="d-flex mb-2">
                  <i class="bi bi-envelope-open text-primary me-2"></i>
                  <p class="mb-0">{HomePageData.EMAIL}</p>
                </div>
                <div class="d-flex mb-2">
                  <i class="bi bi-telephone text-primary me-2"></i>
                  <p class="mb-0">{HomePageData.PHONE_NUMBER}</p>
                </div>
                <div class="d-flex mt-4">
                  <a
                    class="btn btn-primary btn-square rounded-circle me-2"
                     href={HomePageData.FACEBOOK} target="_blank"
                  >
                    <i class="fab fa-facebook-f"></i>
                  </a>
                  <a
                    class="btn btn-primary btn-square rounded-circle me-2"
                     href={HomePageData.INSTAGRAM} target="_blank"
                  >
                    <i class="fab fa-instagram"></i>
                  </a>
                  <a class="btn btn-primary btn-square rounded-circle"  href={HomePageData.YOUTUBE} target="_blank">
                    <i class="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
              {HomePageData.FOOTER_NAV.map((item, index) => (
                <div class="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <h4 class="text-uppercase text-light mb-4">{item.heading}</h4>

                  <div class="d-flex flex-column justify-content-start">
                    {item.links.map((linksItem) => (
                      <NavLink
                        to={linksItem.route}
                        className="text-secondary mb-2"
                        activeClassName="active"
                        exact // Ensure exact match for top-level links
                      >
                        <i class="bi bi-arrow-right text-primary me-2"></i>
                        {linksItem.name}
                      </NavLink>
                      // <a class="text-secondary mb-2" href="index.html">
                      //   <i class="bi bi-arrow-right text-primary me-2"></i>
                      //   {linksItem.name}
                      // </a>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        class="container-fluid py-4 py-lg-0 px-5"
        style={{ background: "#111111" }}
      >
        <div class="row gx-5">
          <div class="col-lg-12">
            <div class="py-lg-4 text-center">
              <p class="text-secondary mb-0">
                &copy;{" "}
                <a class="text-light fw-bold" href="#">
                  mgshooting.in
                </a>
                . All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footers;
