import { HomePageData } from "../../data/data";
import { Link, NavLink } from "react-router-dom";
function Gallery() {
  return (
    <>
      {/* <!-- Hero Start --> */}
      <div class="container-fluid bg-primary p-5 bg-hero mb-5">
        <div class="row py-5">
          <div class="col-12 text-center">
            <h1 class="display-2 text-uppercase text-white mb-md-4">Gallery</h1>
           <NavLink
                        to='/'
                        className="btn btn-primary py-md-3 px-md-5 me-3"
                        exact // Ensure exact match for top-level links
                      >
                        <i class="bi bi-arrow-right text-primary me-2"></i>
                        Home
                      </NavLink>
             <NavLink
                        to='/gallery'
                        className="btn btn-light py-md-3 px-md-5"
                        exact // Ensure exact match for top-level links
                      >
                        Gallery
                      </NavLink>
          </div>
        </div>
      </div>
      {/* <!-- Hero End --> */}

      {/* <!-- Team Start --> */}
      <div class="container-fluid p-5">
        <div class="row g-5">
          {HomePageData.GALLERY.map((item, index) => (
          <div class="col-lg-3 col-md-6">
            <div class="team-item position-relative">
              <div class="position-relative overflow-hidden rounded">
                  <img class="img-fluid w-100" src={item.imgpath} alt="" style={{ height: '320px' }} />
                <div class="team-overlay"></div>
              </div>
              <div
                class="position-absolute start-0 bottom-0 w-100 rounded-bottom text-center p-4"
                style={{ background: "rgba(34, 36, 41, 0.9)" }}
              >
                <h5 class="text-uppercase text-light">{item.title}</h5>
                  <p class="text-uppercase text-secondary m-0">{item.sub_title}</p>
              </div>
            </div>
          </div>
              ))}
        </div>
        <div class="row g-5">
          <div class="col-lg-3 col-md-6">
            <div class="team-item position-relative">
              <div class="position-relative overflow-hidden rounded">
               <video class="img-fluid w-100" width="400" controls style={{ height: '320px' }}>
                  <source src="img/video1.mp4" type="video/mp4" />
               </video>
                {/* <div class="team-overlay"></div> */}
              </div>
              {/* <div
                class="position-absolute start-0 bottom-0 w-100 rounded-bottom text-center p-4"
                style={{ background: "rgba(34, 36, 41, 0.9)" }}
              >
                <h5 class="text-uppercase text-light">Inauguration</h5>
              </div> */}
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="team-item position-relative">
              <div class="position-relative overflow-hidden rounded">
               <video class="img-fluid w-100" width="400" controls style={{ height: '320px' }}>
                  <source src="img/video2.mp4" type="video/mp4" />
               </video>
                {/* <div class="team-overlay"></div> */}
              </div>
              {/* <div
                class="position-absolute start-0 bottom-0 w-100 rounded-bottom text-center p-4"
                style={{ background: "rgba(34, 36, 41, 0.9)" }}
              >
                <h5 class="text-uppercase text-light">Inauguration</h5>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Team End --> */}
    </>
  );
}

export default Gallery;
