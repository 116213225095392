import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Include/ Header";
import Footer from "./components/Include/ Footer";
import Home from "./components/pages/home";
import About from "./components/pages/about";
import Gallery from "./components/pages/gallery";
import EventPage from "./components/pages/event";
import PayAndPlay from "./components/pages/pay-play";
import Contact from "./components/pages/contact";

function App() {
  return (
    <>
      <Router>
        <Header />
        {/* <Home /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/event" element={<EventPage />} />
          <Route path="/pay-play" element={<PayAndPlay />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
