import "../../App.css";
import { HomePageData } from "../../data/data";
import {ContactUsAPI} from "../../service/contactus"
function Home() {
  return (
    <>
      {/* <!-- Carousel Start --> */}
      <div class="container-fluid p-0 mb-5">
        <div
          id="header-carousel"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            {HomePageData.SLIDER_HOME.map((item, index) => (
              <div
                key={index}
                class={`carousel-item ${item.active ? "active" : ""}`}
              >
                <img class="w-100" src={item.imgpath} alt={item.title} />
                <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                  <div class="p-3" style={{ maxWidth: "900px" }}>
                    <h5 class="text-white text-uppercase">{item.title}</h5>
                    <h1 class="display-2 text-white text-uppercase mb-md-4">
                      {item.sub_title}
                    </h1>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      {/* <!-- Carousel End --> */}

      {/* <!-- About Start --> */}
      <div class="container-fluid p-5">
        <div class="row gx-5">
          <div class="col-lg-5 mb-5 mb-lg-0" style={{ minHeight: "500px" }}>
            <div class="position-relative h-100">
              <img
                class="position-absolute w-100 h-100 rounded"
                src="img/about.jpg"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
          <div class="col-lg-7">
            <div class="mb-4">
              <h5 class="text-primary text-uppercase">
                {HomePageData.ABOUT_HEADING}
              </h5>
              <h1 class="display-3 text-uppercase mb-0">
                {HomePageData.ABOUT_SUB_HEADING}
              </h1>
            </div>
            <div class="rounded bg-dark p-5">
              <ul class="nav nav-pills justify-content-between mb-3">
                <li class="nav-item w-50">
                  <a
                    class="nav-link text-uppercase text-center w-100 active"
                    data-bs-toggle="pill"
                    href="#pills-1"
                  >
                    {HomePageData.ABOUT_HEADING}
                  </a>
                </li>
                <li class="nav-item w-50">
                  <a
                    class="nav-link text-uppercase text-center w-100"
                    data-bs-toggle="pill"
                    href="#pills-2"
                  >
                    {HomePageData.WHY_CHOOSE_US}
                  </a>
                </li>
              </ul>
              <div class="tab-content">
                <div class="tab-pane fade show active" id="pills-1">
                  <p class="text-secondary mb-0">
                    {HomePageData.ABOUT_DETAIL_1}
                  </p>
                </div>
                <div class="tab-pane fade" id="pills-2">
                  <p class="text-secondary mb-0">
                    {HomePageData.ABOUT_DETAIL_2}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}

      {/* <!--Commitments--> */}
      <div class="container-fluid p-5">
        <div class="text-center">
          <h5 class="text-primary text-uppercase">{HomePageData.COMMITMENTS_HEADING}</h5>
          <h1 class="display-3 text-uppercase mb-0">{ HomePageData.ABOUT_SUB_HEADING}</h1>
        </div>
        <div class="tab-class text-center">
          {/* <!-- <h4 class="text-body mb-4">At the core of our mission is a commitment to:</h4> --> */}
          <p class="mb-4">
           {HomePageData.COMMITMENTS_TOP_CONTENT}
          </p>
          <div class="tab-content">
            <div class="row g-5 gb-5">
              <div class="col-lg-12 col-md-12">
                {HomePageData.COMMITMENTS_HOME.map((item,index)=>(
                <div class="bg-light rounded text-start p-3">
                  {/* <!-- <i class="flaticon-six-pack display-1 text-primary"></i> --> */}
                  <h3 class="text-uppercase my-2">{item.title}</h3>
                  <p>
                   {item.description}
                  </p>
                  {/* <!-- <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a> --> */}
                </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--Commitments End--> */}

      {/* <!-- Programe Start --> */}
      <div class="container-fluid p-5">
        <div class="text-center">
          <h5 class="text-primary text-uppercase">Mission</h5>
          <h1 class="display-3 text-uppercase mb-0">Our Mission</h1>
        </div>
        <div class="tab-class text-center">
          <h4 class="text-body mb-4">
            At the core of our mission is a commitment to:
          </h4>
          {/* <!-- <p class="mb-4">Nonumy erat diam duo labore clita. Sit magna ipsum dolor sed ea duo at ut. Tempor sit lorem sit magna ipsum duo. Sit eos dolor ut sea rebum, diam sea rebum lorem kasd ut ipsum dolor est ipsum. Et stet amet justo amet clita erat, ipsum sed at ipsum eirmod labore lorem.</p> --> */}
          <div class="tab-content"></div>
        </div>
      </div>
      <div
        class="container-fluid programe position-relative px-5"
        style={{ marginBottom: "135px" }}
      >
        <div class="row g-5 gb-5">
          <div class="col-lg-4 col-md-6">
            <div class="bg-light rounded text-center p-3">
              {/* <!-- <i class="flaticon-six-pack display-1 text-primary"></i> --> */}
              <h3 class="text-uppercase my-4">Safety First</h3>
              <p>
                We prioritize the safety of our participants through rigorous
                adherence to safety protocols and best practices in firearm
                handling.
              </p>
              {/* <!-- <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a> --> */}
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="bg-light rounded text-center p-3">
              {/* <!-- <i class="flaticon-barbell display-1 text-primary"></i> --> */}
              <h3 class="text-uppercase my-4">Education</h3>
              <p>
                We aim to educate and equip individuals with the knowledge and
                skills needed for responsible firearm ownership and use.
              </p>
              {/* <!-- <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a> --> */}
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="bg-light rounded text-center p-3">
              {/* <!-- <i class="flaticon-bodybuilding display-1 text-primary"></i> --> */}
              <h3 class="text-uppercase my-4">Marksmanship Excellence</h3>
              <p>
                We strive to enhance marksmanship skills for participants of all
                levels, from beginners to seasoned professionals.
              </p>
              {/* <!-- <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a> --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Programe Start --> */}

      {/* <!-- Class Timetable Start --> */}
      <div class="container-fluid p-5">
        <div class="mb-5 text-center">
          <h5 class="text-primary text-uppercase">Class Schedule</h5>
          <h1 class="display-3 text-uppercase mb-0">Working Hours</h1>
        </div>
        <div class="tab-class text-center">
          <ul class="nav nav-pills d-inline-flex justify-content-center bg-dark text-uppercase rounded-pill mb-5">
            <li class="nav-item">
              <a
                class="nav-link rounded-pill text-white active"
                data-bs-toggle="pill"
                href="#tab-1"
              >
                Monday
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link rounded-pill text-white"
                data-bs-toggle="pill"
                href="#tab-2"
              >
                Tuesday
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link rounded-pill text-white"
                data-bs-toggle="pill"
                href="#tab-3"
              >
                Wednesday
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link rounded-pill text-white"
                data-bs-toggle="pill"
                href="#tab-4"
              >
                Thursday
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link rounded-pill text-white"
                data-bs-toggle="pill"
                href="#tab-5"
              >
                Friday
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link rounded-pill text-white"
                data-bs-toggle="pill"
                href="#tab-6"
              >
                Saturday
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link rounded-pill text-white"
                data-bs-toggle="pill"
                href="#tab-7"
              >
                Sunday
              </a>
            </li>
          </ul>
          <div className="tab-content">
            {HomePageData.CLASS.map((item, index) => (
              <div
                id={item.tab}
                className={`tab-pane fade show p-0 ${
                  index === 0 ? "active" : ""
                }`}
                key={index}
              >
                <div className="row g-5">
                  {item.timings.map((timingItem, timingIndex) => (
                    <div
                      className="col-lg-3 col-md-4 col-sm-6"
                      key={timingIndex}
                    >
                      <div className="bg-dark rounded text-center py-5 px-3">
                        <h6 className="text-uppercase text-light mb-3">
                          {timingItem.timing}
                        </h6>
                        {/* Add additional elements or data here if needed */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <!-- Class Timetable Start --> */}
    </>
  );
}
export default Home;
